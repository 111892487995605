@mixin DosisRegular($size: 1) {
  font-family: "Dosis Regular", Arial, sans-serif;
  font-size: $size + rem;
  font-weight: 400;
}

@mixin DosisBold($size: 1) {
  font-family: "Dosis Bold", Arial, sans-serif;
  font-size: $size + rem;
  font-weight: 700;
}

@mixin DosisSemiBold($size: 1) {
  font-family: "Dosis SemiBold", Arial, sans-serif;
  font-size: $size + rem;
  font-weight: 600;
}

@mixin DosisMedium($size: 1) {
  font-family: "Dosis Medium", Arial, sans-serif;
  font-size: $size + rem;
}

@mixin DosisLight($size: 1) {
  font-family: "Dosis Light", Arial, sans-serif;
  font-size: $size + rem;
}

@mixin SansProRegular($size: 1) {
  font-family: "SansPro Regular", Arial, sans-serif;
  font-size: $size + rem;
  font-weight: 400;
}

@mixin SansProBold($size: 1) {
  font-family: "SansPro Bold", Arial, sans-serif;
  font-size: $size + rem;
  font-weight: 700;
}

@mixin SansProSemiBold($size: 1) {
  font-family: "SansPro SemiBold", Arial, sans-serif;
  font-size: $size + rem;
  font-weight: 600;
}

@mixin SansProItalic($size: 1) {
  font-family: "SansPro Italic", Arial, sans-serif;
  font-size: $size + rem;
}

@mixin SansProLight($size: 1) {
  font-family: "SansPro Light", Arial, sans-serif;
  font-size: $size + rem;
}
