@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.carousel {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  top: -60px;

  @include screen(1120) {
    top: -30px;
  }

  @include screen(900) {
    top: unset;
    margin-top: 50px;
  }
}
