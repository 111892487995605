@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.fact {
  @extend .flex-center;
  flex-direction: column;
  color: $white;
  margin: 0 12px 24px;

  h3 {
    @include SansProSemiBold(1.375);
    white-space: nowrap;

    @include screen(1199) {
      font-size: 1.25rem;
    }

    @include screen(991) {
      font-size: 1.0625rem;
    }

    @include screen(767) {
      font-size: 1rem;
    }
  }
}