@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.recent-news-carousel {
  .card-body {
    padding: 35px;

    @include screen(1199) {
      padding: 25px 20px;
    }

    .card-title {
      @include SansProSemiBold(1.875);
      margin-bottom: 12px;

      @include screen(1199) {
        font-size: 1.563rem;
      }

      @include screen(991) {
        font-size: 1.375rem;
      }

      @include screen(767) {
        font-size: 1.25rem;
      }

      a {
        color: $primaryText;
        text-decoration: none;

        &:hover {
          color: $brand;
        }
      }
    }
  }
}