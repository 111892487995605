@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.aboutCompany {
  span {
    @include SansProRegular(1.125);
    color: $primaryText;
    text-transform: uppercase;

    @include screen(767) {
      font-size: 1rem;
    }
  }

  h2 {
    @include SansProSemiBold(2.25);
    color: $primaryText;
    line-height: 1.2em;
    margin: 0.33em 0 0.69em;

    @include screen(991) {
      font-size: 1.875rem;
    }

    @include screen(767) {
      font-size: 1.625rem;
    }
  }

  p {
    color: $primaryText;
  }
}

.preLine p {
    white-space: pre-line;
    margin-bottom: 20px;
}

.preLine ul, ol {
  margin-bottom: 20px;
}
