@font-face {
    font-family: 'Dosis Regular';
    src: url(../assets/fonts/Dosis-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Dosis Bold';
    src: url(../assets/fonts/Dosis-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Dosis SemiBold';
    src: url(../assets/fonts/Dosis-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Dosis Medium';
    src: url(../assets/fonts/Dosis-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Dosis Light';
    src: url(../assets/fonts/Dosis-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'SansPro Regular';
    src: url(../assets/fonts/source-sans-pro.regular.ttf) format('truetype');
}

@font-face {
    font-family: 'SansPro Bold';
    src: url(../assets/fonts/source-sans-pro.bold.ttf) format('truetype');
}

@font-face {
    font-family: 'SansPro SemiBold';
    src: url(../assets/fonts/source-sans-pro.semibold.ttf) format('truetype');
}

@font-face {
    font-family: 'SansPro Italic';
    src: url(../assets/fonts/source-sans-pro.italic.ttf) format('truetype');
}

@font-face {
    font-family: 'SansPro Light';
    src: url(../assets/fonts/source-sans-pro.light.ttf) format('truetype');
}
