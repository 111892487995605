@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.causes {
  padding-top: 30px;

  .causeDetails {
    margin-bottom: 35px;

    @include screen(767) {
      padding: 15px 15px;
    }

    .title {
      @include SansProSemiBold(2);
      color: $primaryText;
      padding: 24px 0;

      @include screen(1199) {
        font-size: 1.875rem;
      }

      @include screen(991) {
        font-size: 1.8rem;
      }

      @include screen(767) {
        font-size: 1.5625rem;
      }
    }

    h4 {
      @include SansProSemiBold(1.5);
      margin: 0 0 0.48em;

      @include screen(1199) {
        font-size: 1.125rem;
      }

      .raised {
        color: $brand;
      }

      span {
        @include DosisSemiBold(1.5);

        @include screen(1199) {
          font-size: 1.125rem;
        }
      }
    }

    .progress {
      .progressBar {
        background-color: $brand !important;
        border-radius: 0;
        position: relative;
      }
    }
  }

  .donation {
    margin: 60px 0 40px;
  }

  .reward {
    padding: 20px;
    border: $brand 5px solid;
    margin-bottom: 20px;
  }

  .reward p {
    white-space: pre-line;
  }

  .budget {
    padding: 20px;
    border: #CCC 1px solid;
    margin-bottom: 20px;
  }

  .budget p {
    white-space: pre-line;
  }

  .text,
  .benefits {
    @include SansProRegular(1);
    color: $secondaryText;
    margin-bottom: 1.5em;
  }

  .text p {
    white-space: pre-line;
  }

  .benefits {
    margin: 0;
    padding: 0;

    li {
      @extend .flex-vertical-center;
      list-style: none;

      svg {
        color:  $brand;
        margin-right: 8px;
      }
    }
  }

  .relatedCauses {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @include screen(991) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include screen(680) {
      grid-template-columns: repeat(1, 1fr);
    }

    .relatedCausesCard {
      margin-bottom: 30px;
    }
  }

  .causesInfoImage {
    width: -webkit-fill-available;
  }
}
