@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.about {
  .accordion {
    .accordion-item {
      margin-bottom: 14px;

      .accordion-header {
        .accordion-button {
          @include SansProSemiBold(1);
          @extend .flex-vertical-center;
          justify-content: space-between;
          color: $primaryText;
          padding: 12px 25px;
          box-shadow: 0 0 10px 0 #d9d9d9;

          &:after {
            display: none;
          }

          &[aria-expanded="true"] {
            background: $brand;
            color: $white;

            svg {
              transform: rotate(-180deg);
              color: $white;
            }
          }
        }
      }
    }

    .accordion-body {
      @extend .flex-vertical-center;
      padding: 20px;

      @include screen(460) {
        flex-direction: column;
        align-items: center;
      }

      img {
        width: 175px;
        height: 125px;
        margin-right: 20px;

        @include screen(460) {
          margin-bottom: 14px;
        }
      }

      p {
        @include SansProRegular(1);
        color: $primaryText;
        line-height: 1.6em;
      }
    }
  }
}
