@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.event-info {
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }

  .carousel-indicators {
    margin-bottom: 50px;

    button {
      background: $white;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 0;
      opacity: unset;
      margin: 0 10px 0 0;

      @include screen(1199) {
        width: 12px;
        height: 12px;
      }
    }

    button.active {
      background: $brand;
    }
  }
}