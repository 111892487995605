@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.causeListCard {
  display: flex;
  position: relative;
  margin-bottom: 35px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px 0 #eee;

  .causesListBox {
    display: flex;
    overflow: hidden;

    @include screen(560) {
      display: flex;
      flex-direction: column;
    }

    @include screen(767) {
      padding: 10px;
    }

    .image {
      width: 100%;
      height: 100%;
      max-width: 350px;
      max-height: 400px;
      margin-right: 24px;

      @include screen(1199) {
        max-width: 300px;
      }

      @include screen(1080) {
        max-width: 280px;
      }

      @include screen(900) {
        max-width: 250px;
      }

      @include screen(800) {
        max-width: 200px;
      }
    }
  }

  .details {
    width: 58%;
    padding: 45px 45px 60px 45px;
    position: relative;

    @include screen(1199) {
      padding: 35px 35px 55px 35px;
    }

    @include screen(560) {
      width: 100%;
    }

    @include screen(767) {
      padding: 25px 0 35px 0;
    }

    h3 {
      a {
        @include SansProSemiBold(1.875);
        color: $primaryText;
        text-decoration: none;
        margin: 0 0 0.67em;

        @include screen(1199) {
          font-size: 1.375rem;
        }

        &:hover {
          color: $brand;
        }
      }
    }

    h4 {
      @include SansProSemiBold(1.5);
      margin: 0 0 0.48em;

      @include screen(1199) {
        font-size: 1.125rem;
      }

      .raised {
        color: $brand;
      }

      span {
        @include DosisSemiBold(1.5);

        @include screen(1199) {
          font-size: 1.125rem;
        }
      }
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .actionButton {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
