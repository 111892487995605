@import "../theme/mixins";
@import "../theme/variables";
@import "../theme/theme";

.owl-controls-container {
  display: flex;
  justify-content: center;

  .owl-nav [class*='owl-'] {
    &:hover {
      background: transparent !important;
    }
  }

  .owl-controls {
    position: relative;
    display: inline-block;
    margin: 20px auto 0;

    .owl-nav {
      position: absolute;
      top: 0;
      margin-top: 2px !important;
      display: block;
      width: 100% !important;
      line-height: 0;

      .owl-prev,
      .owl-next {
        position: absolute;

        span {
          color: $primaryText;
          font-size: 2.1875rem;
          font-weight: bold;
          line-height: 0 !important;
        }
      }

      .owl-prev {
        left: -40px;
      }

      .owl-next {
        right: -40px;
      }
    }

    .owl-dot {
      span {
        background: transparent !important;
        width: 14px !important;
        height: 14px !important;
        border: 2px solid $primaryText !important;
        margin: 0 15px 0 0 !important;
        cursor: pointer;
        box-sizing: border-box;
        border-radius: 50%;
      }

      &:last-of-type {
        span {
          margin: 0 !important;
        }
      }

      &.active {
        span {
          background: $brand !important;
          border: none !important;
        }
      }
    }
  }
}