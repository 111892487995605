@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

$spinner-width: 130px;
$spinner-height: 130px;

.info-circle {
  margin-bottom: 24px;

  .circle-progress {
    width: $spinner-width;
    height: $spinner-height;
    display: inline-block;
    position: relative;

    &:after {
      @include DosisSemiBold(2.5);
      color: $brand;
      content: attr(data-pct);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: $spinner-height;
      text-align: center;

      @include screen(1199) {
        font-size: 1.5rem;
      }

      @include screen(767) {
        font-size: 1.5rem;
      }
    }
  }

  .circle-progress-content {
    .bar {
      display: none;
    }
    .bg {
      stroke: $white;
    }
  }
}
