@import "../../theme/theme";

.login {

  text-align: left  ;

  .panel {

    color: #3a3a3a;


    input {
      border: 1px #CCCCCC solid;
      padding: 8px 12px;
      border-radius: 4px;
      color: #3a3a3a;
      -ms-box-sizing:border-box;
      -moz-box-sizing:border-box;
      -webkit-box-sizing:border-box;
      box-sizing:border-box;
      width: 250px;
    }

  }

}

