@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.causes {
  padding-top: 30px;
  .actionButtonContainer {
    display: flex;
    justify-content: center;
    margin: 90px 0;
  }
}
