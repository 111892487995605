@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.date {
  @include SansProSemiBold(1.125);
  @extend .flex-vertical-center;
  display: inline-block;
  color: $white;
  background: $brand;
  padding: 8px 14px;
  text-transform: uppercase;

  @include screen(991) {
    font-size: 0.9375rem;
  }

  @include screen(767) {
    max-width: 150px;
    font-size: 0.8125rem;
    padding: 8px 10px;
  }

  svg {
    margin-right: 6px;
  }
}