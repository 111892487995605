@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.causeCardContainer {
  margin: 0 15px;
  display: flex;
  justify-content: center;

  .causeCard {
    max-width: 570px;

    @include screen(1700) {
      max-width: 400px;
    }

    .causesTitle {
      a {
        text-decoration: none;
        color: inherit;

        &:hover {
          color: $brand;
        }
      }
    }

    .causesPreline {
      height: 50px;
      font-size: 12px;
    }

    .causesPreline p {
      white-space: pre-line;
    }

    .causeImg {
      width: 100%;
      max-width: 570px;
      max-height: 360px;
      background: #000;
    }

    .meter {
      @extend .flex-center;

      svg {
        width: 110px;
        height: 110px;
      }
    }
  }

  .remainingDays {
    @extend .flex-center;
    text-align: center;
    margin-bottom: 18px;

    @include SansProRegular(1.25);
    color: $primaryText;

    @include screen(1199) {
      font-size: 0.9375rem;
    }

    svg {
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }
  }

  .circleProgressContainer {
    @extend .flex-center;
  }

  .numbersContainer {
    display: flex;
    justify-content: space-around;

    .raisedTitle,
    .goalTitle {
      @include SansProSemiBold(1.0625);
      color: $brand;
      text-transform: uppercase;
      text-align: center;
    }

    .raisedNumb,
    .goalNumb {
      @include DosisSemiBold(1.5625);
      color: $primaryText;
      text-transform: uppercase;
      text-align: center;
    }
  }
}
