@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.CH_navbar {
  .navbar-nav {
    .nav-link {
      @include SansProRegular(1.125);
      font-weight: 600;
      color: $nav_text;
      text-transform: uppercase;
      padding: 30px 0;
      margin-right: 30px;

      &:after {
        display: none;
      }

      &:hover {
        color: $brand;
      }
    }
  }

  .navbar-collapse .navbar-nav {
    width: 100%;
    justify-content: end !important;
  }

  .navbar-toggler {
    background-color: $brand;
    width: 40px;
    height: 35px;
    border: 0;
    padding: 5px 10px;
    border-radius: 3px;
    outline: 0;

    &:focus {
      box-shadow: none;
    }

    svg {
      color: $white;
    }
  }

  .dropdown-menu.show {
    padding: 0;
    border-radius: 0;

    .dropdown-item {
      @include SansProSemiBold(1);
      color: $nav_text;
      padding: 12px 15px;
      transition: all 0.3s;

      &:first-child {
        border-top: 3px solid $brand;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid #ccc;
      }

      &:hover {
        background: transparent;
        color: $brand;
        padding-left: 20px;
      }
    }
  }
}

.CH_dropdown-title-responsive {
  .CH_dropdown-title-mobile {
    display: none;

    @include screen(991) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .CH_dropdown-title-icon {
        width: 40px;
        height: 35px;
        height: -webkit-fill-available;
        box-sizing: border-box;
        border-left: 1px solid #cccccc;
        position: absolute;
        right: 0;
        cursor: pointer;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .CH_dropdown-title-desktop {
    @extend .flex-vertical-center;

    svg {
      margin-left: 5px;
      font-size: 1rem;
    }

    @include screen(991) {
      display: none;
    }
  }
}

.show {
  max-width: 90vw;


  .offcanvas-header {
    position: absolute;
    top: 0;
    left: 0;

    .CH_close-button {
      @extend .flex-center;
      width: 35px;
      height: 35px;
      position: absolute;
      top: 10px;
      left: -18px;
      z-index: 20;
      border-radius: 50%;
      border: 0;
      outline: 0;
      background: $brand;

      svg {
        color: $white;
      }
    }
  }

  .offcanvas-body {
    padding: 0;

    .CH_mobile-nav {
      .nav-link,
      a {
        @include SansProSemiBold(0.9375);
        padding: 10px 15px 10px 35px;
        box-sizing: border-box;
        color: $nav_text;
        text-transform: uppercase;
        border-bottom: 1px solid #cccccc;

        &:hover {
          color: $brand;
        }

        &:after {
          display: none;
        }
      }
    }

    .dropdown-menu {
      border: 0;
      padding: 0;
      margin: 0;

      a {
        @include SansProSemiBold(0.9375);
        padding: 6px 15px 6px 45px;
        text-transform: capitalize;
        color: #000;
        border-bottom: 1px solid #cccccc;
        cursor: pointer;

        &:hover {
          background: transparent;
          color: $brand;
        }
      }
    }
  }
}
