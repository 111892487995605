@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.eventInfoContainer {
  margin-top: 30px;
  margin-bottom: 50px;
  .eventDetails {
    padding: 45px 100px 0 0;

    @include screen(1199) {
      padding: 45px 35px 0 0;
    }

    @include screen(767) {
      padding: 30px 15px 0 0;
    }

    .title {
      @include SansProSemiBold(1.875);
      color: $primaryText;
      margin: 0 0 0.5em;

      @include screen(991) {
        font-size: 1.6875rem;
      }

      @include screen(767) {
        font-size: 1.4375rem;
      }
    }

    .text {
      @include SansProRegular(1);
      color: $secondaryText;
      margin-bottom: 1.5em;
    }
  }

  .eventInfo {
    @extend .flex-vertical-center;
    flex-wrap: wrap;
    padding: 0;

    li {
      @include SansProSemiBold(1.125);
      @extend .flex-vertical-center;
      list-style: none;
      padding: 0;
      margin-right: 40px;

      @include screen(991) {
        font-size: 1rem;
        margin-right: 20px;
      }

      @include screen(767) {
        font-size: 0.9375rem;
        margin: 0 15px 5px 0;
      }

      svg {
        color: $brand;
        margin-right: 10px;

        @include screen(767) {
          margin-right: 6px;
        }
      }
    }
  }
}

.preLine p {
    white-space: pre-line;
    margin-bottom: 20px;
}

.preLine ul, ol {
  margin-bottom: 20px;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}
