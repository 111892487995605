@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.tabs {
  .moreEvents {
    @extend .flex-center;
    margin-top: 70px;
  }

  @include screen(991) {
    margin-top: 50px;
  }
}