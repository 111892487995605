@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.facts {
  padding: 80px 0 56px;
  background: rgba(18, 58, 84, 0.8);
  background-repeat: no-repeat;
  background-attachment: fixed;

  @include screen(767) {
    padding: 60px 0 10px;
  }

  .content {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
}