@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.footer {
  background-color: #181818;
  color: $white;
  padding: 50px 0 120px;

  @include screen(1199) {
    padding: 110px 0 100px;
  }

  @include screen(991) {
    padding: 100px 0 40px;
  }

  @include screen(767) {
    padding: 80px 0 20px;
  }

  .footerCol {
    @include screen(991) {
      margin-bottom: 50px;
    }
  }

  .footerLogo {
    margin-bottom: 20px;
    max-width: 250px;

    @include screen(991) {
      max-width: 200px;
      margin-bottom: 17px;
    }

    .logo {
      max-width: 90%;
    }
  }

  .aboutWidget {
    p {
      @include SansProRegular(1.0625);
      color: $white;
      line-height: 1.8em;

      @include screen(1199) {
        font-size: 1rem;
      }

      @include screen(767) {
        font-size: 0.9375rem;
      }
    }
  }

  .companyInfo {
    margin-bottom: 20px;
    a {
      color: #FFF;
    }
  }

  .socialLinks {
    margin: 0;
    padding: 0;
    display: flex;
    padding-left: 20px;

    li {
      @include SansProRegular(1.0625);
      list-style: none;
      color: $white;
      margin-right: 30px;

      &:not(:last-of-type) {
        margin-bottom: 14px;
      }

      @include screen(1199) {
        font-size: 1rem;
      }

      @include screen(1199) {
        margin-right: 12px;
      }

      @include screen(767) {
        font-size: 0.9375rem;
      }

      &:hover {
        color: $brand;
      }
    }

    a {
      font-size: 1.375rem;
      color: #fff;
      text-decoration: none;

      @include screen(991) {
        font-size: 1.125rem;
      }

      svg {
        transition: all 1s;
      }

      &:hover {
        svg {
          transform: rotate(360deg);
          color: $brand;
        }
      }
    }
  }

  .widgetTitle {
    @include SansProBold(1.875);
    margin: 16px 0 1.5em;
    text-transform: uppercase;

    @include screen(1199) {
      font-size: 1.5625rem;
      margin: 13px 0 1.5em;
    }

    @include screen(991) {
      font-size: 1.4375rem;
      margin: 12px 0 1.3em;
    }

    @include screen(767) {
      font-size: 1.25rem;
      margin: 0 0 1em;
    }
  }

  .donateWidget {
    @include screen(1200) {
      padding-left: 30px;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        margin-bottom: 14px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          @include SansProRegular(1.063);
          color: #fff;
          text-decoration: none;

          @include screen(1200) {
            font-size: 1rem;
          }

          @include screen(767) {
            font-size: 0.938rem;
          }

          &:hover {
            color: $brand;
          }
        }
      }
    }
  }

  .twitterCol {
    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;

        a {
          color: $brand;
        }
      }
    }
  }
}
