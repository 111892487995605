@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.cause-list-card {
  .progress {
    background-color: $primaryText;
    height: 12px;
    margin-bottom: 28px;
    overflow: visible;
    border-radius: 0;
    position: relative;

    @include screen(1199) {
      height: 10px;
    }

    @include screen(767) {
      margin: 0 0 25px;
    }
  }

  .progress-bar {
    background-color: $brand;
    border-radius: 0;
    position: relative;
    overflow: visible;

    @include screen(1199) {
      height: 10px;

      &:after {
        width: 20px;
        height: 20px;
        top: -5px;
      }
    }

    &:after {
      content: "";
      background-color: $white;
      width: 24px;
      height: 24px;
      position: absolute;
      right: -10px;
      top: -6px;
      border-radius: 50%;
      box-shadow: 0 0 10px 0 #999999;
    }
  }
}
