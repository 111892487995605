@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.card {
  width: 100%;
  max-width: 300px;


  .grid {
    background-color: $white;
    padding: 35px 35px;
    text-align: center;
    max-width: 390px;
    transition: all 0.3s;
    box-shadow: 0 0 15px 0 #e6e6e6;
    box-sizing: border-box;
    margin-bottom: 30px;

    @include screen(1199) {
      padding: 35px 25px;
    }

    @include screen(767px) {
      padding: 25px 15px;
    }

  }

  .grid:hover {
    background-color: $brand;
  }

  .grid:hover .info h3 a,
  .grid:hover .info h4 a,
  .grid:hover .info p,
  .grid:hover .icon i:before,
  .grid:hover .details,
  .grid:hover svg {
    color: $white;
    text-decoration: none;
  }

  .icon i:before,
  svg {
    font-size: 2.5rem;
    color: $brand;
    transition: all 0.3s;

    @include screen(1199) {
      font-size: 2rem;
    }
  }

  .info h3, h4 {
    @include SansProSemiBold(1.75);
    margin: 0.5em 0;
    color: $primaryText;

    @include screen(1199) {
      font-size: 1.375rem;
    }

    @include screen(767px) {
      font-size: 1.25rem;
    }

    a {
      text-decoration: none;
      color: $primaryText;
    }
  }

  .info p {
    margin-bottom: 25px;
    transition: all 0.3s;
    color: $secondaryText;
    line-height: 1.8em;

    @include screen(1199) {
      font-size: 0.938rem;
      margin-bottom: 20px;
    }
  }

  .details {
    @include SansProSemiBold(1.25);
    color: $brand;
    text-decoration: none;

    @include screen(1199) {
      font-size: 1.063rem;
    }
  }
}
