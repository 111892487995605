@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.cause-carousel {
  .card-body {
    .card-title {
      @include SansProRegular(1.625);
      color: $primaryText;
      text-align: center;
      margin-bottom: 12px;
    }
  }
}