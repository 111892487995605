@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.CH_navbar {
  background-color: #F6F6F6;
  padding: 0;

  .navbarContainer {
    max-width: 1200px;

    @include screen(991) {
      max-width: unset;
      padding: 12px 18px;
    }
  }

  .logo {
    margin-right: 16px;

    img {
      @include screen(991) {
        max-width: 180px;
      }
    }
  }
}
