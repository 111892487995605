@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.button {
  @include SansProRegular(1.125);
  background-color: $brand;
  height: 45px;
  line-height: 45px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  padding: 0 25px 0 25px;
  border: 0;
  display: inline-block;
  border-radius: 0;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  touch-action: manipulation;
  user-select: none;
  transition: all 0.3s;
  text-decoration: none;

  @include screen(991) {
    height: 40px;
    line-height: 40px;
    font-size: 1rem;
    padding: 0 35px;
  }

  @include screen(767) {
    height: 35px;
    line-height: 35px;
    font-size: 0.875rem;
    padding: 0 30px;
  }

  span {
    position: relative;
  }

  &:hover {
    span {
      animation-name: buttonActionAnimate;
      animation-duration: 0.2s;
    }
  }

  &:disabled {
    opacity: .2;
    pointer-events: none;
  }

  svg {
    margin-left: 12px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}

@keyframes buttonActionAnimate {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}

.mini {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color:  $brand;
  background-color: unset;
  padding: 0 15px;
}

.secondary {
  color:  $brand;
  background-color: unset;
  padding: 0 25px 0 25px;
}

.border {
  border: $brand 1px solid;
}
