@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.event {
  background-color: #F0F3F8;
  display: flex;
  margin-bottom: 25px;
  position: relative;

  .eventDate {
    position: absolute;
    top: 0;
    left: 0;
  }

  .eventDetails {
    padding: 65px 60px 35px;
    position: relative;

    @include screen(991) {
      padding: 60px 35px 25px;;
    }

    @include screen(767) {
      padding: 50px 20px 25px;
    }

    h3 {
      a {
        @include SansProSemiBold(1.875);
        color: $primaryText;
        text-decoration: none;
        margin: 0 0 0.23em;

        &:hover {
          color: $brand;
        }

        @include screen(991) {
          font-size: 1.563rem;
        }

        @include screen(767) {
          font-size: 1.375rem;
          margin-bottom: 10px;
        }
      }
    }

    .eventInfo {
      @extend .flex-vertical-center;
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        margin-bottom: 20px;
        color: $secondaryText;

        &:not(:last-of-type) {
          margin-right: 20px;
        }

        @include screen(767) {
          margin-bottom: 10px;
        }

        svg {
          margin-right: 4px;
        }
      }
    }

    .text {
      margin-bottom: 28px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      .preLine {
        white-space: pre-line;
      }


      @include screen(991) {
        margin-bottom: 20px;
      }

      @include screen(767) {
        margin-bottom: 10px;
      }
    }

    .joinLink {
      @include SansProSemiBold(1.25);
      text-decoration: none;
      color: $brand;
      text-transform: capitalize;

      @include screen(991) {
        font-size: 1rem;

        svg {
          font-size: 0.875rem;
        }
      }

      &:hover {
        color: #156751;
      }

      svg {
        margin-left: 5px;
        font-size: 1rem;
      }
    }
  }

  .image {
    width: 100%;
    min-width: 300px;
    min-height: 300px;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include screen(1199) {
      display: none;
    }
  }
}
