@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.main-carousel {
  .carousel-indicators {
    display: none;
  }

  .main-carousel-image {
    max-height: 600px;
    width: 100%;
    object-fit: cover;
    filter: brightness(40%);
    @include screen(560) {
      max-height: 400px;
    }
  }
}
