@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.categoriesWrapper {
  background: #f0f3f8;
  padding: 90px 16px;

  &.white {
    background: $white;
  }
}

.categories {
  max-width: 1170px;
  margin: 0 auto;

  .title,
  .text {
    max-width: 620px;
  }

  .title {
    @include SansProSemiBold(2.68);
    @extend .flex-center;
    color: $primaryText;
    margin: 0 auto 20px;
  }

  .text {
    @include SansProRegular(1.0625);
    @extend .flex-center;
    color: $secondaryText;
    margin: 0 auto 40px;
  }
}
