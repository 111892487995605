@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.ch_tabs {
  .nav.nav-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 40px !important;

    .nav-item {
      .nav-link {
        @include SansProSemiBold(1.375);
        color: $primaryText;
        border-radius: unset;

        @include screen(991) {
          font-size: 1.125rem;
        }

        @include screen(767) {
          font-size: 0.9375rem;
          padding: 8px 12px;
        }
      }

      .nav-link.active {
        background: $brand;
        color: $white;
      }
    }
  }
}