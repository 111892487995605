@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.main {
  .categoriesList {
    box-sizing: border-box;
    justify-content: center;
    padding: 0 3%;
  }

  .latestProductContainer {
    padding-bottom: 40px;
  }

  .factCircle {
    margin-bottom: 24px;
  }
}

.causeDetails {
  margin-bottom: 35px;

  @include screen(767) {
    padding: 15px 15px;
  }

  .title {
    @include SansProSemiBold(2);
    color: $primaryText;
    padding: 24px 0 12px 0;

    @include screen(1199) {
      font-size: 1.875rem;
    }

    @include screen(991) {
      font-size: 1.8rem;
    }

    @include screen(767) {
      font-size: 1.5625rem;
    }
  }

  h4 {
    @include SansProSemiBold(1.5);
    margin: 0 0 0.48em;

    @include screen(1199) {
      font-size: 1.125rem;
    }

    .raised {
      color: $brand;
    }

    span {
      @include DosisSemiBold(1.5);

      @include screen(1199) {
        font-size: 1.125rem;
      }
    }
  }

  .progress {
    .progressBar {
      background-color: $brand !important;
      border-radius: 0;
      position: relative;
    }
  }
}

.relatedCauses {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @include screen(991) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include screen(680) {
    grid-template-columns: repeat(1, 1fr);
  }

  .relatedCausesCard {
    margin-bottom: 30px;
  }
}
