@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.article {
  margin-bottom: 65px;

  &:last-of-type {
    margin-bottom: 20px;
  }

  &:not(:first-of-type) {
    img {
      margin-top: 14px;
    }
  }

  @include screen(991) {
    margin-bottom: 50px;
  }

  @include screen(767) {
    margin-bottom: 45px;
  }

  img {
    width: 100%;
    height: 100%;
    max-width: 230px;
    max-height: 140px;
  }

  .heading {
    @include SansProSemiBold(1.875);
    margin: 0 0 0.8em;
    text-transform: capitalize;

    @include screen(767) {
      font-size: 1.25rem;
    }
  }

  h4 {
    a {
      @include SansProRegular(1.125);
      text-decoration: none;
      color: $primaryText;
      font-weight: normal;
      margin: 0 0 0.2em;

      @include screen(767) {
        font-size: 0.9375rem
      }

      &:hover {
        color: $brand;
      }
    }
  }

  .info {
    @include SansProRegular(0.875);
    color: #808080FF;

    span {
      margin-left: 8px;

      svg {
        margin-right: 4px;
      }
    }
  }
}