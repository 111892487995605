@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.donationSection {
  text-align: center;
  margin: 0;

  .form {
    position: relative;

    &::-webkit-input-placeholder {
      font-style: normal;
      color: #fff;
    }

    .donateList {
      overflow: hidden;
      margin-top: 30px;

      .box {
        height: 50px;
        line-height: 50px;
        float: left;
        background: $primaryText;
        width: 187px;
        margin-right: 15px;
        margin-bottom: 20px;

        &:last-of-type {
          margin: 0;
        }

        @include screen(1199) {
          width: 163px;
        }

        @include screen(991) {
          width: 165px;
          height: 45px;
          line-height: 45px;
          margin-right: 20px;
        }

        @include screen(767) {
          width: 100%;
          height: 40px;
          line-height: 40px;
          margin: 0 0 15px;
        }
      }

      .active {
        background: $brand;
      }
    }

    input[type="radio"] {
      &:checked {
        & + label {
          .checkIcon {
            &:before {
              background: $primaryText;
            }
          }
        }
      }
    }


    input[type="radio"] {
      display: none;
    }

    input[type="radio"] + label {
      @include DosisSemiBold(1.125);
      color: $white;

      @include screen(991) {
        font-size: 1rem;
      }
    }

    input[type="radio"] {
      & + label {
        .checkIcon {
          &:after {
            content: "";
            width:18px;
            height:14px;
            background: url(../../assets/images/checkbox-check.png) -18px top no-repeat;
            cursor:pointer;
            position: absolute;
            left: -22px;
            top: 6px;
          }
        }
      }
    }

    input[type="radio"] {
      &:checked {
        & + label {
          .checkIcon {
            &:after {
              content: "";
              background:url(../../assets/images/checkbox-check.png) left top no-repeat;
            }
          }
        }
      }
    }

    label {
      width: 100%;
      cursor: pointer;

      span {
        display: inline-block;
        min-height: 22px;
      }

      .amount {
        position: relative;
        left: -10px;
      }

      .checkIcon {
        width: 22px;
        height: 22px;
        position: relative;
        z-index: 10;
      }

      .checkIcon {
        &:before {
          content: "";
          background: $brand;
          width: 22px;
          height: 22px;
          position: absolute;
          left: -25px;
          top: 5px;
          z-index: -1;
          transition: all 0.2s;
          border-radius: 50%;
        }
      }
    }

    .enterAmount {
      width: 187px;
      height: 50px;
      line-height: 50px;
      background: $primaryText;

      input {
        @include SansProSemiBold(1);
        background-color: $primaryText;
        width: 100%;
        height: 100%;
        font-weight: 600;
        color: #fff;
        padding: 10px 15px;
        border: 0;
        outline: 0;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        @include screen(991) {
          font-size: 0.9375rem;
        }
      }
    }

    .amountAction {
      width: 100%;
    }
  }
}
