@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.about {
  max-width: 1170px;
  margin: 20px auto;
  box-sizing: border-box;
  padding-top: 20px;

  @include screen(991) {
    padding: 90px 0 100px;
  }

  @include screen(767) {
    padding: 70px 0 80px;
  }

  .aboutContainer {
    justify-content: space-around;

    .aboutCol {
      @include screen(991) {
        max-width: 90vw;
      }
    }
  }

  .aboutCompany {
    span {
      @include SansProRegular(1.125);
      color: $primaryText;
      text-transform: uppercase;

      @include screen(767) {
        font-size: 1rem;
      }
    }

    h2 {
      @include SansProSemiBold(2.25);
      color: $primaryText;
      line-height: 1.2em;
      margin: 0.33em 0 0.69em;

      @include screen(991) {
        font-size: 1.875rem;
      }

      @include screen(767) {
        font-size: 1.625rem;
      }
    }

    p {
      color: $primaryText;
      margin-bottom: 50px;
    }
  }

  .help {
    position: relative;
    margin-bottom: 30px;

    @include screen(1199) {
      max-width: 560px;
    }

    .imgHolder {
      img {
        width: 100%;
        height: 100%;
        max-width: 560px;
        max-height: 500px;
      }

      .videoPlay {
        @include SansProRegular(1.5);
        text-decoration: none;
        color: $white;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 60px;

        &:hover {
          text-decoration: underline;
        }

        @include screen(767) {
          font-size: 1.25rem;
          bottom: 40px;
        }

        svg {
          color: $brand;
          display: inline-block;
          padding-right: 10px;
        }
      }
    }
  }

  .listInfo {
    overflow: hidden;
    margin: 0 -15px;
    padding: 5px 0;

    li {
      list-style: none;
      width: 33.33%;
      float: left;
      padding: 0 15px;

      @include screen(767) {
        width: 100%;
        float: none;
        margin-bottom: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .infoBox {
      background-color: $white;
      padding: 15px 25px 15px 65px;
      text-align: center;
      box-shadow: 0 0 12px 0 #e6e6e6;
      position: relative;

      @include screen(767) {
        padding: 15px 25px;
      }

      .icon {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);

        @include screen(767) {
          position: relative;
          left: -25px;
          top: -10px;
          transform: none;
        }

        svg {
          font-size: 1.875rem;
          color: $brand;

          @include screen(767) {
            font-size: 1.5625rem;
          }
        }
      }

      h4 {
        @include SansProBold(1.625);
        color: $primaryText;
        margin: 0;
        text-transform: uppercase;
        white-space: nowrap;

        @include screen(991) {
          font-size: 1.375rem;
        }

        @include screen(767) {
          font-size: 1.5625rem;
          margin-top: 10px;
        }
      }

      .details {
        @include screen(767) {
          display: inline-block;
        }

        span {
          @include SansProRegular(1);
          color: $primaryText;
          white-space: nowrap;
        }
      }
    }
  }
}
