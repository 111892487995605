@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.aside {
  padding-left: 45px;
  padding-right: 20px;

  @include screen(1199) {
    padding-left: 25px;
    padding-right: 10px;
  }

  @include screen(991) {
    padding-left: 15px;
    margin-top: 100px;
  }

  @include screen(767) {
    margin-top: 60px;
  }
}