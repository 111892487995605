@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.about {
  .container {
    margin: 20px auto;

    .info {
      @include screen(991) {
        padding: 0 80px;
      }

      @include screen(767) {
        padding: 0 40px;
      }
    }
  }

  .chart {
    @include screen(991) {
      display: none;
    }
  }

  .someInfo {
    margin-top: 30px;
    margin-left: 40px;
    min-height: 420px;
    position: relative;

    .circle {
      background-color: #e6f2ef;
      width: 400px;
      height: 400px;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      border-radius: 50%;

      &:before {
        content: "";
        background-color: rgba(35, 168, 132, 0.5);
        width: 180px;
        height: 180px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }

      &:after {
        content: "";
        background-color: $brand;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
    }

    .infoBox {
      background-color: $white;
      padding: 15px 25px 15px 80px;
      text-align: center;
      box-shadow: 0 0 12px 0 #e6e6e6;
      position: absolute;

      &:before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
      }

      &:nth-child(2) {
        left: -30px;
        top: -30px;
      }

      &:nth-child(2):before {
        border-bottom: 15px solid transparent;
        border-right: 15px solid $white;
        bottom: -15px;
      }

      &:nth-child(3) {
        top: 120px;
        left: 50%;
        transform: translateY(-50%);
      }

      &:nth-child(3):before {
        border-bottom: 15px solid transparent;
        border-left: 15px solid $white;
        bottom: -15px;
        right: auto;
        left: 0;
      }

      &:last-child {
        left: 0%;
        top: 60%;
      }

      &:last-child:before {
        border-left: 15px solid transparent;
        border-bottom: 15px solid $white;
        bottom: -15px;
        right: 0;
        left: auto;
        top: -15px;
        bottom: auto;
      }

      .icon {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);

        svg {
          font-size: 2.1875rem;
          color: $brand;
        }
      }

      .details {
        h4 {
          @include SansProBold(1.875);
          color: $primaryText;
          margin: 0;
          text-transform: uppercase;
        }

        span {
          @include SansProRegular(1);
          color: $primaryText;
        }
      }
    }
  }

  .featuredContainer,
  .proudPartners {
    background: $white;
  }

  .actionVolunteers {
    @extend .flex-center;
  }
}
