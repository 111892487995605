@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.eventTab {
  margin-bottom: 0;
  overflow: hidden;
  display: flex;
  background: $white;

  &:not(:last-of-type) {
    margin-bottom: 30px;
  }

  @include screen(991) {
    flex-direction: column;
    max-width: 720px;
    //align-items: center;
    margin: 0 auto;
  }

  .date {
    width: 200px;
    padding: 24px 35px;
    text-align: center;
    color: $white;
    background: $brand;

    @include screen(991) {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 15px;
    }

    .calendarIcon {
      svg {
        font-size: 2.5rem;
        margin-bottom: 12px;

        @include screen(991) {
          font-size: 1.5625rem;
          margin-right: 15px;
        }

        @include screen(767) {
          font-size: 1.125rem;
        }
      }
    }

    h5 {
      @include SansProSemiBold(1.875);
      text-transform: uppercase;

      @include screen(991) {
        font-size: 1.375rem;
      }

      @include screen(767) {
        font-size: 1.125rem;
      }
    }

    .year {
      @include SansProSemiBold(1.125);
      display: block;

      @include screen(767) {
        font-size: 0.9375rem;
      }
    }
  }

  .eventDetails {
    width: 100%;
    max-width: 740px;
    padding: 50px;

    @include screen(991) {
      padding: 25px;
      text-align: center;
    }

    h3 {
      @include SansProSemiBold(1.875);
      margin: 0 0 5px;
      text-transform: capitalize;

      @include screen(991) {
        font-size: 1.5625rem;
      }

      @include screen(767) {
        font-size: 1.25rem;
        margin: 0 0 10px;
      }

      a {
        color: $primaryText;
        text-decoration: none;

        &:hover {
          color: $brand;
        }
      }
    }

    .info {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      margin-top: 15px;

      @include screen(991) {
        justify-content: center;
      }

      li {
        @extend .flex-vertical-center;
        list-style: none;
        color: $primaryText;

        &:not(:last-of-type) {
          margin-right: 20px;
        }

        svg {
          margin-right: 4px;
        }
      }
    }
  }

  .eventPic {
    margin-left: auto;

    @include screen(1199) {
      display: none;
    }

    img {
      height: 100%;
      max-height: 200px;
    }
  }
}
