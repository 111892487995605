body {
    font-family: "SansPro Regular", Arial, sans-serif;
    font-size: 1rem;
}

strong {
    font-weight: 600;
}

div, p, main, header, footer {
    padding: 0;
    margin: 0;
    word-break: break-word;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

hr {
    border: none;
    height: 1px;
    color: #CCC;
    background-color: #CCC;
    display: block;
}
