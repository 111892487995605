@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.header {
  margin: 0 auto;

  .topBar {
    line-height: 60px;
    border-bottom: 1px solid #e7e7e7;

    @include screen(991) {
      line-height: 45px;
      padding: 0 18px;
    }

    .topBarContainer {
      max-width: 1200px;
      box-sizing: border-box;

      @include screen(991) {
        max-width: none;
      }

      @include screen(767) {
        padding: 10px 8px 15px;
      }
    }
  }

  .topBarList {
    @extend .flex-vertical-center;
    margin: 0;
    padding: 0;

    @include screen(767) {
      flex-direction: column;
    }

    li {
      @include SansProRegular(1.125);
      @extend .flex-vertical-center;
      color: $medium-gray;
      list-style: none;
      line-height: 60px;
      box-sizing: border-box;
      padding-right: 25px;
      margin-right: 25px;
      white-space: nowrap;

      @include screen(991) {
        font-size: 1rem;
        padding-right: 15px;
        margin-right: 15px;
        line-height: 45px;
      }

      &:first-of-type {
        border-right: 1px solid #e6e6e6;

        @include screen(767) {
          border: 0;
        }
      }

      @include screen(767) {
        margin: 0;
        padding: 0;
        border: 0;
      }

      svg {
        width: 18px;
        margin-right: 4px;
      }
    }
  }

  .contactContainer {
    @include screen(767) {
      justify-content: center;
    }
  }

  .donateContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 0 0 12px;

    @include screen(767) {
      justify-content: center;
    }
  }

  .socialFollowDonate {
    display: flex;
    align-items: center;
    line-height: 60px;

    @include screen(991) {
      line-height: 45px;
    }

    @include screen(767) {
      line-height: 30px;
    }

    .socialFollow {
      display: flex;
      margin-right: 30px;

      @include screen(991) {
        margin-right: 20px;
      }

      span {
        @include SansProSemiBold($donateTextSize);
        color: $medium-gray;
        margin-right: 30px;
        white-space: nowrap;
        position: relative;
        text-transform: uppercase;

        @include screen(991) {
          font-size: 1rem;
          margin-right: 20px;
        }
      }

      .socialLinks {
        display: flex;
        margin: 0;
        padding: 0;

        li {
          list-style: none;

          svg {
            color: $medium-gray;
            margin-right: 10px;
            margin-left: 10px;
          }

          &:first-of-type {
            border-right: 0;
          }
        }

        svg {
          margin-right: 3px;
        }
      }
    }

    .donate {
      background: $brand;
      white-space: nowrap;

      a {
        @include SansProSemiBold($donateTextSize);
        padding: 0 30px;
        color: $white;
        text-transform: uppercase;
        text-decoration: none;

        @include screen(991) {
          font-size: 1rem;
          padding: 0 15px;
        }

        svg {
          margin-right: 12px;
          position: relative;
          color: $white;

          @include screen(991) {
            font-size: 1.125rem;
            margin-right: 8px;
          }
        }
      }
    }
  }
}
