@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

$spinner-width: 110px;
$spinner-height: 110px;
$g09: #ccc;

.circle-progress {
  width: $spinner-width;
  height: $spinner-height;
  display: inline-block;
  position: relative;

  &:after {
    @include DosisSemiBold(1.25);
    color: $primaryText;
    content: attr(data-pct)"%";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: $spinner-height;
    text-align: center;
  }

  .circle-progress-content {
    transform: rotate(-90deg);

    .bar, .bg {
      stroke-linecap: round;
    }
    .bar {
      stroke: $brand;
      transition: stroke-dashoffset 0.1s ease-out;
    }
    .bg {
      stroke: $g09;
    }
  }
}
