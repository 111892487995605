@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.newsCard {
  max-width: 560px;
  overflow: hidden;
  margin: 0 auto;

  .imageContainer {
    position: relative;

    .organization {
      content: "";
      width: 140px;
      height: 40px;
      position: absolute;
      right: -25px;
      bottom: 0;
      background: $white;
      transform: skewX(-45deg);

      span {
        @include SansProSemiBold(1.125);
        color: $brand;
        position: absolute;
        top: 25%;
        left: 20%;
        transform: skewX(45deg);
      }
    }
  }

  .info {
    @include SansProRegular(1.125);
    color: $secondaryText;
    padding-bottom: 12px;
    border-bottom: 1px solid #e6e6e6;

    @include screen(1199) {
      font-size: 1rem;
    }
  }

  .link {
    @include SansProRegular(1.375);
    padding-top: 20px;

    @include screen(1199) {
      font-size: 1.0625rem;
    }

    @include screen(767) {
      font-size: 1.0625rem;
    }

    a {
      color: $secondaryText;
      text-decoration: none;

      &:hover {
        color: $brand;
      }
    }
  }
}
