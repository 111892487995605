@import "variables";
@import "mixins";

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

@mixin screen($custom: 1920) {
  @media screen and (max-width: $custom + px) { @content; }
}

@mixin desktopLarge {
  @media screen and (max-width: 1920px) { @content; }
}

@mixin desktopMedium {
  @media screen and (max-width: 1700px) { @content; }
}

@mixin desktop {
  @media screen and (max-width: 1260px) { @content; }
}

@mixin tablet {
  @media screen and (max-width: 960px) { @content; }
}

@mixin tabletSmall {
  @media screen and (max-width: 740px) { @content; }
}

@mixin mobile {
  @media screen and (max-width: 600px) { @content; }
}