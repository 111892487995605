@import '~bootstrap/scss/bootstrap';
@import './styles/fonts.css';
@import './styles/common.css';
@import './styles/owl-controls.scss';
@import "theme/mixins";
@import "theme/variables";
@import "theme/theme";

body {
    @include SansProRegular(1);
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;

    @include screen(767) {
        font-size: 14px;
    }
}

.App {
    .container {
        max-width: 1200px;
        margin: 0 auto;
    }
}

.owl-carousel {
    .owl-stage {
        display: flex;
    }
    .owl-item {
        display: flex;
        flex: 1 0 auto;
    }
}