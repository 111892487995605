@import "../../theme/mixins";
@import "../../theme/variables";
@import "../../theme/theme";

.mainCarousel {
  .carouselCaption {
    top: 50%;
    transform: translateY(-55%);
    max-width: 750px;
    margin: 0 auto;
    padding: 0 24px;
    box-sizing: border-box;

    @include screen(1300) {
      top: 40%;
      transform: translateY(-50%);
    }

    @include screen(560) {
      padding: 0;
    }
  }

  .mainCarouselAction {
    @include screen(560) {
      height: 30px;
      line-height: 30px;
      padding: 0 18px;
    }
  }

  h3 {
    @include SansProRegular(2.5);
    color: $white;
    margin-bottom: 45px;
    text-transform: capitalize;

    @include screen(1120) {
      font-size: 2.4rem;
      margin-bottom: 34px;
    }

    @include screen(991) {
      font-size: 2rem;
      margin-bottom: 24px;
    }

    @include screen(767) {
      font-size: 1.875rem;
    }

    @include screen(560) {
      font-size: 1.25rem;
      margin-bottom: 12px;
    }
  }

  p {
    @include SansProRegular(1.0625);
    margin: 0 0 45px 0;

    @include screen(1120) {
      margin-bottom: 34px;
      font-size: 1rem;
    }

    @include screen(560) {
      font-size: 0.875rem;
      margin-bottom: 12px;
    }
  }
}
